import { Checkbox, Flex, FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { useField } from "formik";

interface IProps {
  label: string;
  name: string;
  mb?: string;
  width?: string;
  options: string[];
}

const InputCheckboxGroup = ({ label, mb, options, width, ...props }: IProps) => {
  const [, { error, touched, value }, { setValue }] = useField(props);

  const handleChange = (event: any) => {
    const { value: evtValue, checked: evtChecked } = event.target;
    let newValue;
    if (evtChecked) {
      newValue = [...value, evtValue];
    } else {
      newValue = value.filter((item: string) => item !== evtValue);
    }
    setValue(newValue);
  };

  return (
    <FormControl width={width} p={1} mb={mb ? mb : 0} isInvalid={touched && error ? true : false}>
      {label && <FormLabel pl={2} mb={0} mt={2}><em>{label}</em></FormLabel>}
      <Flex alignItems={'center'} justifyContent={'space-between'} flexWrap={'wrap'} p={1} mb={1}>
      {options.map((item, i) => {     
        return (
            <div key={i}>
              <Checkbox
                id={`${props.name}-${i}`} 
                value={item} 
                checked={value.includes(item)} 
                onChange={handleChange} 
              >
              {item}
              </Checkbox>
            </div>
          )
      })}
      </Flex>
      {error && <FormErrorMessage ml={2} mt={1}>{error}</FormErrorMessage>}
    </FormControl>
  )
}

export default InputCheckboxGroup;

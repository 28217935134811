import { Box, Flex, Grid, Heading, Img, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, SimpleGrid, Stack, Text, Textarea, useMediaQuery } from "@chakra-ui/react"
import { Layout } from "../../sistema/components/Layout"
import colorsConfig from "../../config/colorsConfig"
import { useNavigate } from "react-router-dom";
import React, { ReactNode } from "react";
import topo from "../../../images/mobile/telaInicial/topo.png";
import topoDesktop from "../../../images/desktop/telaInicial/topo.png";
import tituloProjetos from "../../../images/mobile/telaInicial/tituloProjetos.png";
import tituloValores from "../../../images/mobile/telaInicial/tituloValores.png";
import fotosAfumg from "../../../images/mobile/telaInicial/fotosAfumg.png";
import fotosResidencial from "../../../images/mobile/telaInicial/fotosResidencial.png";
import fotoMoinho from "../../../images/desktop/telaInicial/fotoMoinho.png";
import tituloProposito from "../../../images/mobile/telaInicial/tituloProposito.png";
import fotosAbaixoProposito from "../../../images/mobile/telaInicial/fotosAbaixoProposito.png";
import fotosAbaixoPropositoGradiente from "../../../images/mobile/telaInicial/fotosAbaixoPropositoGradiente.png";
import tituloDepoimentos from "../../../images/mobile/telaInicial/tituloDepoimentos.png";
import depoimento1 from "../../../images/mobile/telaInicial/depoimento1.png";
import depoimento2 from "../../../images/mobile/telaInicial/depoimento2.png";
import depoimento3 from "../../../images/mobile/telaInicial/depoimento3.png";
import depoimento1Desktop from "../../../images/desktop/telaInicial/depoimento1.png";
import depoimento2Desktop from "../../../images/desktop/telaInicial/depoimento2.png";
import depoimento3Desktop from "../../../images/desktop/telaInicial/depoimento3.png";
import tituloBeneficios from "../../../images/mobile/telaInicial/tituloBeneficios.png";
import tituloCodigoEdita from "../../../images/mobile/telaInicial/tituloCodigoEdita.png";
import tituloReconhecimentos from "../../../images/mobile/telaInicial/tituloReconhecimentos.png";
import certificacaoAgronegocio from "../../../images/mobile/telaInicial/certificacoes/agronegocio.png";
import certificacaoIndustria from "../../../images/mobile/telaInicial/certificacoes/industria.png";
import certificacaoParana from "../../../images/mobile/telaInicial/certificacoes/parana.png";
import certificacaoSesi from "../../../images/mobile/telaInicial/certificacoes/sesi.png";
import certificacaoOds from "../../../images/mobile/telaInicial/certificacoes/ods.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// @ts-ignore
import Slider from "react-slick";
import iconeVisao from "../../../images/pins/eye.png";
import iconeMissao from "../../../images/pins/arrow.png";

import pin1 from "../../../images/pins/pin1.png";
import pin2 from "../../../images/pins/pin2.png";
import pin3 from "../../../images/pins/pin3.png";
import pin4 from "../../../images/pins/pin4.png";
import pin5 from "../../../images/pins/pin5.png";
import pin6 from "../../../images/pins/pin6.png";
import pin7 from "../../../images/pins/pin7.png";
import pin8 from "../../../images/pins/pin8.png";
import pin9 from "../../../images/pins/pin9.png";
import pin10 from "../../../images/pins/pin10.png";
import pin11 from "../../../images/pins/pin11.png";
import pin12 from "../../../images/pins/pin12.png";
import pin13 from "../../../images/pins/pin13.png";
import pin14 from "../../../images/pins/pin14.png";
import pin15 from "../../../images/pins/pin15.png";
import pin16 from "../../../images/pins/pin16.png";

import { FiMenu } from "react-icons/fi";

export const Introducao = () => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();

  const Certificacao = ({ isMobile = true }) => {
    return (
      <SimpleGrid columns={5} spacing={0} my={4}>
        <Img maxW={isMobile ? '70px' : '200px'} src={certificacaoAgronegocio} />
        <Img maxW={isMobile ? '70px' : '200px'} src={certificacaoIndustria} />
        <Img maxW={isMobile ? '70px' : '200px'} src={certificacaoParana} />
        <Img maxW={isMobile ? '70px' : '200px'} src={certificacaoSesi} />
        <Img maxW={isMobile ? '70px' : '200px'} src={certificacaoOds} />
    </SimpleGrid>
    )
  }

  enum PosicaoTitulo {
    esquerda = 'esquerda',
    centro = 'centro',
  }

  type IPropsBlocoTituloDescricao = {
    titulo: string;
    posicaoTitulo: PosicaoTitulo;
    descricao: ReactNode;
    titulo2?: string;
  }

  const BlocoTituloDescricao = ({ titulo, titulo2, posicaoTitulo, descricao }: IPropsBlocoTituloDescricao) => {
    return (
      <Box width={"full"} px={4} mt={8}>
        <Box mb={3} width={"full"} textAlign={posicaoTitulo == PosicaoTitulo.esquerda ? "left" : "center"}>
          {posicaoTitulo == PosicaoTitulo.esquerda && <Heading size={isMobile ? 'xl' : '2xl'}>
            {titulo}
          </Heading>}
          {posicaoTitulo == PosicaoTitulo.centro && <Text fontWeight={600} lineHeight={"40px"} fontSize={isMobile ? "28px" : "45px"}>
            <em> {titulo} </em>
            {titulo2 && <> <br /> <em> {titulo2} </em> </>}
          </Text>}
        </Box>
        <Box width={"full"} textAlign={"justify"} lineHeight={isMobile ? "18px" : "25px"} fontSize={isMobile ? "16px" : "23px"}>
          {descricao}
        </Box>
      </Box>
    )
  }

  const BlocoPessoas = ({ isMobile = true }) => {
    const arrayPessoas = [
      {
        letra: 'P',
        significado: 'Paixão',
        frase: 'Pelo que fazemos',
      },
      {
        letra: 'E',
        significado: 'Engajamento',
        frase: 'Juntos somos mais fortes',
      },
      {
        letra: 'S',
        significado: 'Sustentabilidade',
        frase: 'Pensando no futuro',
      },
      {
        letra: 'S',
        significado: 'Simplicidade',
        frase: 'Esse é o nosso jeito de fazer o bem',
      },
      {
        letra: 'O',
        significado: 'Obstinado',
        frase: 'Incansáveis pelo resultado',
      },
      {
        letra: 'A',
        significado: 'Agilidade',
        frase: 'Somos ágeis nos negócios',
      },
      {
        letra: 'S',
        significado: 'Serviço',
        frase: 'Proativos no servir',
      },
    ]

    return (
      <Flex mb={6} width={"full"} wrap={"wrap"} justifyContent={"space-around"}>
        {arrayPessoas.map((item, index) => (
          <Box textAlign={"center"} key={index} width={`${100 / arrayPessoas.length}%`}>
            <Text fontSize={isMobile ? "35px" : "65px"} fontWeight={700}>
              {item.letra}
            </Text>
            <Text fontSize={isMobile ? "6px" : "14px"} fontWeight={700}>
              {item.significado}
            </Text>
            <Text fontSize={isMobile ? "8px" : '15px'} >
              {item.frase}
            </Text>
          </Box>
        ))
        }
      </Flex >
    )
  }

  const pinImages = [
    pin1,
    pin2,
    pin3,
    pin4,
    pin5,
    pin6,
    pin7,
    pin8,
    pin9,
    pin10,
    pin11,
    pin12,
    pin13,
    pin14,
    pin15,
    pin16,
  ];

  const PinsSliders = ({ isMobile = true }: { isMobile?: boolean }) => {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <Slider {...settings}>
        {pinImages.map((i, index) => (
          <Flex mx={"auto"} justifyContent={"center"} textAlign={"center"} px={"70px"} key={index}>
            <Img mx={"auto"} width={isMobile ? '' : '330px'} src={i} />
          </Flex>
        ))}
      </Slider>
    );
  }

  const SobreNos = () => {
    return <>
      O Moinho Globo é uma empresa inovadora, que cresce e se destaca nacionalmente como grande produtora de farinha de trigo e derivados. E toda a base do nosso sucesso está nas pessoas. Se você procura uma empresa que valoriza o talento e se preocupa genuinamente com o bem-estar dos colaboradores, está no lugar certo.
      <br /> <br /> Aqui cultivamos um ambiente que valoriza a inovação, o crescimento profissional e o trabalho em equipe. Estamos comprometidos em oferecer mais do que um simples emprego. Proporcionamos oportunidades para desenvolver habilidades, alcançar metas e construir uma carreira.
    </>
  }

  const Afumg = () => {
    return <>
      A AFUMG (Associação dos Funcionários do Moinho Globo) se destaca por oferecer aos colaboradores uma ampla e bem cuidada estrutura, com salão de festas, espaço gourmet, parquinho para crianças, campo de futebol, quadra, horta e pomar. Também é na sede da Afumg que o Moinho Globo realiza suas tradicionais confraternizações de fim de ano, festas juninas, dia das crianças e várias outras.
    </>
  }

  const Residencial = () => {
    return <>
      Você sabia que o Moinho Globo ajudou a criar um bairro com casas para seus funcionários? Isso mesmo! É o Residencial Moinho Globo, que fica próximo da indústria e da Afumg.
      <br /> <br /> São mais de 140 casas, entregues no ano de 2013. Os funcionários puderam realizar o sonho da casa própria adquirindo os imóveis por valores inferiores à média de mercado graças ao envolvimento da empresa, que não visou qualquer lucro.
    </>
  }

  const BlocoProposito = () => {
    return (
      <Flex wrap={'wrap'} width={'full'} flexDir={'column'} pt={12}>
        <Flex py={12} justifyContent={'center'} textAlign={'center'} fontSize={'x-large'} fontWeight={'900'}>
          <Heading size={isMobile ?  'md' : 'lg'}>
            Nosso PROPÓSITO é proporcionar
          </Heading>
        </Flex>

        <Flex justifyContent={'center'} textAlign={'center'} fontSize={'xxx-large'} fontWeight={'900'} p={2}>
        <Heading size={isMobile ? 'xl' : '3xl'}>
          Momentos incomparáveis, <br />
          conquistar corações
          </Heading>
        </Flex>
      </Flex>
    )
  }


  const ImagemProposito = ({ maxW = '1200px' }) => {
    return (
        <Box
            width={"full"}
            backgroundImage={`url(${fotosAbaixoPropositoGradiente})`}
            backgroundSize={'cover'}
            backgroundPosition={'center'}
            paddingBottom={"60px"}
        >
            <Img maxW={maxW} src={fotosAbaixoProposito} height={"auto"} mx={"auto"} />
        </Box>
    )
  }
  
  const BlocoMissaoEVisao = ({ isMobile = false }) => {
    return (
      <Flex flexWrap={'wrap'} py={10}>
        <Flex width={isMobile ? '100%' : '50%'} flexWrap={'wrap'}>
          <Flex>
            <Flex pt={2}>
              <Img src={iconeMissao} width={"90px"} height={"80px"} mx={"auto"} />
            </Flex>
            <Flex flexWrap={'wrap'} flexDirection={'column'} width={'full'} pl={2} pr={isMobile ? 0 : 6}>
              <Flex fontSize={'xx-large'} fontWeight={'700'} p={2}>
                Missão
              </Flex>
              <Box>
                ANTECIPAR AS NECESSIDADES DOS CLIENTES,
                OFERECENDO SOLUÇÕES CRIATIVAS EM ALIMENTOS, {' '}
                {isMobile ? <React.Fragment>
                  <Text as="span" pt={'2px'} pb={'1px'} px={2} rounded={'15px'} bg={'blue.600'} color={'white'}>
                    PROMOVENDO EXPERIÊNCIAS
                  </Text> <br />
                  <Text as="span" pt={'2px'} pb={'1px'} px={2} rounded={'15px'} bg={'blue.600'} color={'white'}>
                    QUE GEREM VALOR.
                  </Text>
                </React.Fragment>: 
                <React.Fragment>
                <Text as="span" pt={'1px'} pb={'2px'} px={2} rounded={'15px'} bg={'blue.600'} color={'white'}>
                  PROMOVENDO EXPERIÊNCIAS QUE GEREM VALOR.
                </Text> 
              </React.Fragment>}
              </Box>
            </Flex>
          </Flex>
        </Flex>
        <Flex width={isMobile ? '100%' : '50%'} flexWrap={'wrap'} mt={isMobile ? '4' : '0'}>
          <Flex>
            <Flex pt={2}>
              <Img src={iconeVisao} width={"90px"} height={"80px"} mx={"auto"} />
            </Flex>
            <Flex flexWrap={'wrap'} flexDirection={'column'} width={'full'} pl={2} pr={isMobile ? 0 : 6}>
              <Flex fontSize={'xx-large'} fontWeight={'700'} p={2}>
                Visão
              </Flex>
              <Box>
                SER REFERÊNCIA EM CRESCIMENTO SUSTENTÁVEL
                POR MEIO DA INOVAÇÃO E PRESENÇA NO MERCADO,
                TORNANDO-SE {isMobile && <React.Fragment><br /></React.Fragment>}
                <Text mx={0.5} as="span" pt={'2px'} pb={'1px'} px={2} rounded={'15px'} bg={'blue.600'} color={'white'}>
                  A MELHOR OPÇÃO
                </Text>
                DOS NOSSOS CLIENTES.
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    )
  }

  if (isMobile) {
    return (
      <Layout
        header={
          <Flex
            as="header"
            borderBottom={`1px solid ${colorsConfig.bgColor}`}
            p={3}
            pl={6}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Img height={"40px"} src='https://saerpapi.moinhoglobo.com.br/storage/logo.svg' />

            <Box cursor={"pointer"}>
              <Popover colorScheme="blue">
                <PopoverTrigger>
                  <Flex my="auto">
                    <FiMenu color={colorsConfig.bgColor} size={"30px"} />
                  </Flex>
                </PopoverTrigger>
                <PopoverContent boxSize="xxs">
                  <Flex>
                    <PopoverArrow />
                    <PopoverBody>
                      <Text borderBottom={`1px solid ${colorsConfig.bgColor}`} onClick={() => navigate('/vagas')}>
                        Conhecer Vagas
                      </Text>
                    </PopoverBody>
                  </Flex>
                </PopoverContent>
              </Popover>
            </Box>
          </Flex>
        }
        footer={
          <Box
            onClick={() => navigate('/vagas')}
            as="footer"
            position="fixed"
            bottom="0"
            width="100%"
            bg={colorsConfig.bgColor}
            color={colorsConfig.color}
            p={3}
            borderTopRadius={"60px"}
            fontSize={"30px"}
            fontWeight={600}
            textAlign={"center"}
          >
            Conhecer vagas
          </Box>
        }>
        <Box width={"full"} color={colorsConfig.bgColor}>
          <Img src={topo} />

          <BlocoTituloDescricao
            posicaoTitulo={PosicaoTitulo.esquerda}
            titulo="Sobre nós"
            descricao={<SobreNos />}
          />

          <iframe style={{ margin: '30px auto' }} width={window.screen.width - 10} height="300" src="https://www.youtube.com/embed/L4CtKxy-tPk?si=3CkvHEGghX3Hr7ea&amp" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>


          <Img src={fotoMoinho} my={10} />

          <Img src={tituloProjetos} width={"60%"} height={"auto"} mx={"auto"} />


          <BlocoTituloDescricao
            posicaoTitulo={PosicaoTitulo.centro}
            titulo="AFUMG"
            descricao={<Afumg />}
          />

          <Img mt={6} width={"85%"} height={"auto"} mx={"auto"} src={fotosAfumg} />

          <BlocoTituloDescricao
            posicaoTitulo={PosicaoTitulo.centro}
            titulo="Residencial"
            titulo2="Moinho Globo"
            descricao={<Residencial />}
          />

          <Img mt={6} width={"65%"} height={"auto"} mx={"auto"} src={fotosResidencial} />

          <BlocoProposito />

          <Box width={"full"}>
            <BlocoMissaoEVisao isMobile={true} />

            <Img my={8} src={tituloValores} width={"60%"} height={"auto"} mx={"auto"} />

            <BlocoPessoas />
            <ImagemProposito maxW="" />
          </Box>

          <Img mt={8} src={tituloDepoimentos} width={"70%"} height={"auto"} mx={"auto"} />

          <Img mt={8} src={depoimento1} width={"85%"} height={"auto"} mx={"auto"} />
          <Img mt={4} src={depoimento2} width={"85%"} height={"auto"} mx={"auto"} />
          <Img mt={4} src={depoimento3} width={"85%"} height={"auto"} mx={"auto"} />

          <Img mb={5} mt={8} src={tituloBeneficios} width={"70%"} height={"auto"} mx={"auto"} />

          <Box maxW={"80%"} mx={"auto"}>
            <PinsSliders />
          </Box>

          <Img mt={8} src={tituloCodigoEdita} width={"70%"} height={"auto"} mx={"auto"} />

          <iframe style={{ margin: 'auto' }} width={window.screen.width - 10} height="300" src="https://www.youtube.com/embed/PW50pi8jp6c?si=iKjJUQq4wqlD1PS8&amp" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>

          <Img mt={8} src={tituloReconhecimentos} width={"70%"} height={"auto"} mx={"auto"} />
          
          <Certificacao />
        </Box>
      </Layout>
    )
  }

  return (
    <Layout
      header={
        <Box as="header" width={"full"} borderBottom={`1px solid ${colorsConfig.bgColor}`}>
          <Flex
            p={3}
            pl={6}
            justifyContent={"space-between"}
            alignItems={"center"}
            maxW={"1000px"}
            wrap={"wrap"}
            mx={"auto"}
          >
            <Img height={"40px"} src='https://saerpapi.moinhoglobo.com.br/storage/logo.svg' />

            <Box cursor={"pointer"}>
              <Popover colorScheme="blue">
                <PopoverTrigger>
                  <Flex my="auto">
                    <FiMenu color={colorsConfig.bgColor} size={"30px"} />
                  </Flex>
                </PopoverTrigger>
                <PopoverContent boxSize="xxs">
                  <Flex>
                    <PopoverArrow />
                    <PopoverBody>
                      <Text borderBottom={`1px solid ${colorsConfig.bgColor}`} onClick={() => navigate('/vagas')}>
                        Conhecer Vagas
                      </Text>
                    </PopoverBody>
                  </Flex>
                </PopoverContent>
              </Popover>
            </Box>
          </Flex>
        </Box>
      }
      footer={
        <Flex as="footer" width={"full"} justifyContent={"center"}>
          <Box
            mx={"auto"}
            maxW={"1200px"}
            onClick={() => navigate('/vagas')}
            position="fixed"
            bottom="0"
            width="100%"
            bg={colorsConfig.bgColor}
            color={colorsConfig.color}
            p={3}
            borderTopRadius={"60px"}
            fontSize={"30px"}
            fontWeight={600}
            textAlign={"center"}
            boxShadow='0 0 0 2px gray'
            cursor={"pointer"}
          >
            Conhecer vagas
          </Box>
        </Flex>
      }>
      <Box width={"full"} maxW={"full"} mx={"auto"} color={colorsConfig.bgColor}>
        <Img width={"99vw"} maxW={"1920px"} mx={"auto"} src={topoDesktop} />
        <Box mt={1} width={"full"} maxW={"1200px"} mx={"auto"} color={colorsConfig.bgColor}>
          <BlocoTituloDescricao
            posicaoTitulo={PosicaoTitulo.esquerda}
            titulo="Sobre nós"
            descricao={<SobreNos />}
          />

        </Box>

        <iframe style={{ margin: '30px auto' }} width="1000" height="450" src="https://www.youtube.com/embed/L4CtKxy-tPk?si=3CkvHEGghX3Hr7ea&amp" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>

        <Img width={"99vw"} maxW={"1920px"} mx={"auto"} mt={8} src={fotoMoinho} />

        <Box mt={1} width={"full"} maxW={"1200px"} mx={"auto"} color={colorsConfig.bgColor}>
          <Img src={tituloProjetos} mt={4} width={"25%"} height={"auto"} mx={"auto"} />

          <BlocoTituloDescricao
            posicaoTitulo={PosicaoTitulo.centro}
            titulo="AFUMG"
            descricao={<Afumg />}
          />

          <Img mt={6} width={"45%"} height={"auto"} mx={"auto"} src={fotosAfumg} />

          <BlocoTituloDescricao
            posicaoTitulo={PosicaoTitulo.centro}
            titulo="Residencial"
            titulo2="Moinho Globo"
            descricao={<Residencial />}
          />

          <Img mt={6} width={"25%"} height={"auto"} mx={"auto"} src={fotosResidencial} />

          <BlocoProposito />

          <Box width={"full"}>
            <BlocoMissaoEVisao />

            <Img my={8} src={tituloValores} width={"30%"} height={"auto"} mx={"auto"} />
            
            <BlocoPessoas isMobile={false} />
          </Box>

        </Box>
        <ImagemProposito />
        <Box mt={1} width={"full"} maxW={"1200px"} mx={"auto"} color={colorsConfig.bgColor}>

          <Img mt={8} src={tituloDepoimentos} width={"35%"} height={"auto"} mx={"auto"} />

          <Img mt={8} src={depoimento1Desktop} width={"100%"} height={"auto"} mx={"auto"} />
          <Img mt={4} src={depoimento2Desktop} width={"100%"} height={"auto"} mx={"auto"} />
          <Img mt={4} src={depoimento3Desktop} width={"100%"} height={"auto"} mx={"auto"} />

          <Img mb={5} mt={8} src={tituloBeneficios} width={"35%"} height={"auto"} mx={"auto"} />

          <Box maxW={"95%"} mx={"auto"}>
            <PinsSliders isMobile={false} />
          </Box>

          <Img mt={8} src={tituloCodigoEdita} width={"35%"} height={"auto"} mx={"auto"} />


          <Box width={'full'} textAlign={"center"}>
            <iframe style={{ margin: 'auto' }} width="800" height="450" src="https://www.youtube.com/embed/PW50pi8jp6c?si=iKjJUQq4wqlD1PS8&amp" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
          </Box>

          <Img mt={8} src={tituloReconhecimentos} width={"35%"} height={"auto"} mx={"auto"} />

          <Certificacao isMobile={false} />
        </Box>
      </Box>
    </Layout>
  )
}
import { replaceAllFunction } from "./replaceAll";

interface IValidationFields {
  [key: string]: string;
}

const validateForm = (validation: IValidationFields, form_values: any) => {
  let object = {};

  Object.keys(validation).forEach((element) => {
    const [type, valor] = validation[element].split("|");

    if (type === "required" && !form_values[element]) {
      object = {
        ...object,
        [element]: "Este campo é obrigatório.",
      };
    }

    if (
      type === "min" &&
      (!form_values[element] || form_values[element].length < parseInt(valor))
    ) {
      let message = `Este campo precisa ter ao menos ${valor} caracteres.`;
      if (typeof form_values[element] != "string") {
        if (valor == "1") {
          message = `Selecione ao menos ${valor} opção.`;
        } else {
          message = `Selecione ao menos ${valor} opções.`;
        }
      }
      object = {
        ...object,
        [element]: message,
      };
    }

    if (type === "celular") {
      if (
        !form_values[element] ||
        (form_values[element] &&
          replaceAllFunction(
            form_values[element]
              .replace("(", "")
              .replace(")", "")
              .replace("-", ""),
            "_",
            ""
          ).length < 10)
      ) {
        object = {
          ...object,
          [element]: `Coloque um celular válido.`,
        };
      }
    }
  });

  if (Object.keys(object).length === 0) {
    return {};
  }

  return object;
};

export default validateForm;

import { Box, CircularProgress, Flex, Img, Text, useMediaQuery } from "@chakra-ui/react";
import { Layout } from "../../sistema/components/Layout";
import { useEffect, useState } from "react";
import { Vaga } from "../types/vaga";
import colorsConfig from "../../config/colorsConfig";
import { apiCall } from "../../api/apiNoAuthCall";
import banner_vagas_1 from "../../../images/mobile/telaVagas/banner_vagas_1.png";
import { useNavigate } from "react-router-dom";
import { formatData } from "../../../utils/formatData";

export const Vagas = () => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const [vagas, setVagas] = useState<Vaga[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await apiCall({ url: `/portal-candidatura/vagas`, method: 'GET' });

        setVagas(data);
        setIsLoading(false);
      } catch (err: any) {
        setIsLoading(false);
        setError('Ocorreu um erro ao buscar vagas, se candidate no banco de currículos.')
      }
    }
    getData();
  }, []);

  const navigate = useNavigate();

  if (true) {
    return (
      <Layout>
        <Box maxW={"700px"} mx="auto">
          <Img width={"100%"} height={"auto"} src={banner_vagas_1} />

          <Box px={5}>
            <Text color={colorsConfig.bgColor} mb={6} mt={10}>
              Moinho Globo | <b>Venha fazer parte!</b>
            </Text>
            <Text as={"em"} color={colorsConfig.bgColor}>
              <b>Como é trabalhar no Moinho Globo?</b>
            </Text>
            <Text color={colorsConfig.bgColor} mt={4}>
              Nós oferecemos um ambiente de trabalho
              colaborativo, oportunidades de crescimento e
              desenvolvimento profissional, além de uma cultura
              organizacional acolhedora, temos benefícios que só
              o Moinho Globo pode te oferecer!
            </Text>
            <Text color={colorsConfig.bgColor} mb={6} mt={4}>
              Se você se identificou conosco e deseja fazer parte
              da transformação do mercado de trigo, ajudando a
              levar um alimento extremamente importante à mesa
              das famílias, junte-se à equipe do Moinho Globo!

            </Text>

            {!!error && <Box borderRadius={"5px"} bg={"red.100"} borderColor={"red.300"} borderWidth={"1px"} p={4} textAlign={"center"} mb={4}>
              {error}
            </Box>}

            {isLoading && <Box mb={4} width={"full"} textAlign={"center"}>
              <CircularProgress isIndeterminate color={colorsConfig.bgColor} />
            </Box>}

            {vagas.map((vaga, index) => (
              <Flex cursor={"pointer"} key={index} onClick={() => navigate(`/vagas/${vaga.ukey}`)} mb={5} borderRadius={"20px"} width={"full"} direction={"column"} bg={colorsConfig.bgColor} color={colorsConfig.color} px={5} py={5}>
                <Text fontWeight={600} fontSize={'18px'}>
                  {vaga.nome.toUpperCase()}
                </Text>
                <Text mt={1} fontWeight={400} fontSize={'15px'}>
                  <em>{vaga.regiao}</em>
                </Text>
                <Text mt={1} fontWeight={400} fontSize={'15px'}>
                  {formatData(vaga.data)}
                </Text>
              </Flex>
            ))}

            {!error && <Box mb={5} color={colorsConfig.bgColor} width={"full"} textAlign={"center"} fontWeight={600} fontSize={"14px"} lineHeight={"16px"}>
              Não encontrou a vaga desejada? <br />
              Anexe seu currículo abaixo:
            </Box>}

            <Flex cursor={"pointer"} onClick={() => navigate('/vagas/banco-de-curriculos')} borderRadius={"20px"} width={"full"} direction={"column"} bg={colorsConfig.bgColor} color={colorsConfig.color} px={5} py={10}>
              <Text fontWeight={600} fontSize={'18px'}>
                BANCO DE CURRÍCULOS
              </Text>
            </Flex>
          </Box>
        </Box>
      </Layout>
    )
  }

  return (
    <Layout><></></Layout>
  )
}
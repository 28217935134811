import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { FiArrowRightCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Layout } from "../components/Layout";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <Flex width="full" justifyContent="center" wrap="wrap">
        <Heading mt={6}>Pagina não encontrada</Heading>

        <Button mt={6} mb={4} colorScheme="blue" padding={6} onClick={() => navigate('/')}>
          <Text mr={2}>IR PARA O INÍCIO</Text>
          <Flex>
            <FiArrowRightCircle fontSize="1.8rem" />
          </Flex>
        </Button>
      </Flex>
    </Layout>
  )
}

export default NotFound;
import { format } from "date-fns";

export const formatData = (
  data: string | number,
  template = "dd/MM/yyyy"
) => {
  if (!data) {
    return "";
  }
  return format(new Date(data), template);
};

import React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { useField } from "formik";
import { InputHTMLAttributes } from "react";

export type InputSelectProps = InputHTMLAttributes<HTMLSelectElement> & {
  name: string;
  label: string;
  noPlaceholer?: boolean;
  bgNone?: boolean;
  onChangeVal?: (val: string) => void;
  onChangeSetValue?: (val: string) => void;
};

const InputSelect: React.FC<InputSelectProps> = ({
  label,
  bgNone = false,
  size: _,
  width,
  onChangeVal,
  onChangeSetValue,
  noPlaceholer = false,
  ...props
}) => {
  const [field, { error }, { setValue }] = useField(props);
  return (
    <FormControl isInvalid={!!error} width={width} p={1}>
      {label && <FormLabel pl={2} mb={0} mt={2}><em>{label}</em></FormLabel>}
      <Select
        onChange={(e) => { typeof onChangeSetValue === 'function' ? onChangeSetValue(e.target.value) : setValue(e.target.value, false); typeof onChangeVal === 'function' && onChangeVal(e.target.value) }}
        value={field.value}
        {...props}
        id={field.name}
        bg={!bgNone ? 'white' : 'none'}
        color={!bgNone ? 'black' : 'white'}
        borderRadius={'15px'}
        borderColor={'#6d6d6d'}
      >
        {props.children}
      </Select>
      {error && <FormErrorMessage ml={2} mt={1}>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default InputSelect;

import { Routes, Route } from "react-router-dom";
import NotFound from "../sistema/views/NotFound";
import { Introducao } from "../candidatura/views/Introducao";
import { Vagas } from "../candidatura/views/Vagas";
import { Candidatura } from "../candidatura/views/Candidatura";

export const Router: React.FC<{}> = () => {
  return (
    <Routes>
      <Route path="/" element={<Introducao />} />
      <Route path="/vagas" element={<Vagas />} />
      <Route path="/vagas/:rh_vaga_ukey" element={<Candidatura />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

import { Button, CircularProgress, Flex, FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { useField } from "formik";
import { useState } from "react";
import { FiCamera, FiCheck, FiX } from "react-icons/fi";
import { mensagemErro } from "../../../utils/toasts";

interface IProps {
  label: string;
  name: string;
  mb?: string;
  placeholder: string;
  width?: string;
}

interface Event<T = EventTarget> {
  target: T;
}

const InputFile = ({ label, mb, placeholder, width, ...props }: IProps) => {
  const [, { error, touched, value }, { setValue }] = useField(props);

  const [isLoading, setIsLoading] = useState(false);

  const randomId = Math.floor(Math.random() * 100).toString();

  const open = () => {
    document.getElementById(`image-field${randomId}`)?.click();
  }

  const isPDF = (file: any) => {
    return file.name.toLowerCase().endsWith('.pdf');
  }

  const verificaTamanho = (file: any, tamanhoMaximoEmMb = 50) => {
    return file.size <= tamanhoMaximoEmMb * 1024 * 1024;
  }

  const ajustImage = (event: Event<HTMLInputElement>) => {
    let fileList = event.target.files;
    if (!fileList?.length) {
      return;
    }
    let file = fileList[0];

    if (!isPDF(file)) {
      mensagemErro("Somente é aceito arquivos no formato PDF.")
      return
    }

    if (!verificaTamanho(file)) {
      mensagemErro("O arquivo precisa ter no máximo 50 MB de tamanho.")
      return
    }

    setIsLoading(true)
    let reader = new FileReader();
    reader.onloadend = () => {
      setValue(reader.result);
      setIsLoading(false)
    };
    reader.onerror = () => {
      mensagemErro("O seu arquivo está corrompido, escolha outro.")
      setIsLoading(false);
    }
    reader.readAsDataURL(file);
  };

  return (
    <FormControl width={width} p={1} mb={mb ? mb : 0} isInvalid={touched && error ? true : false}>
      {label && <FormLabel pl={2} mb={0} mt={2}><em>{label}</em></FormLabel>}
      <Button height={"2.5rem"} width={width} borderRadius={"15px"} bg={'#b5c0cc'} color={'#082e56'} size="sm" mr={1} borderWidth={error && '2px'} borderColor="red" onClick={open}>{isLoading ? <><CircularProgress size={"25px"} mx={"auto"} isIndeterminate color="#b5c0cc" /></> : <><em> {value ? 'Currículo anexado!' : placeholder} </em></>} </Button>
      <input accept="application/pdf" type="file" onChange={ajustImage} style={{ display: 'none' }} id={`image-field${randomId}`} />
      {error && <FormErrorMessage ml={2} mt={1}>{error}</FormErrorMessage>}
    </FormControl>
  )
}

export default InputFile;
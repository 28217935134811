import axios from "axios";
import { AxiosRequestConfig } from "axios";
import getEnv from "../../utils/getEnv";

export const APIURL: string = getEnv("REACT_APP_API_HOST");

export const apiCall = (config: AxiosRequestConfig) =>
  axios({
    ...config,
    baseURL: APIURL,
  });

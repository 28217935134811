import { Box, Button, CircularProgress, Flex, Heading, Link, Text, useMediaQuery } from "@chakra-ui/react";
import { Layout } from "../../sistema/components/Layout";
import { useEffect, useState } from "react";
import { Vaga } from "../types/vaga";
import colorsConfig from "../../config/colorsConfig";
import { apiCall } from "../../api/apiNoAuthCall";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import validateForm from "../../../utils/validateForm";
import { mensagemErro } from "../../../utils/toasts";
import InputField from "../../sistema/components/InputField";
import { BobjetoPadrao } from "../../sistema/components/BobjetoPadrao";
import InputFile from "../../sistema/components/InputFile";
import InputCheckboxGroup from "../../sistema/components/InputCheckboxGroup";
import { formatError } from "../../../utils/formatError";
import InputSelect from "../../sistema/components/InputSelect";
import { SelectInput } from "../types/select_input";

type ICandidaditaForm = {
  nome: string;
  email: string;
  celular: string;
  resumo: string;
  setor_pretendido: number | string;
  cargo_pretendido: string;
  a24_ukey: string | null;
  curriculo: string;
  rh_vaga_ukey: string | null;
  cpf: string;
  pcd: number;
  pcd_detalhes: string[];
}

const novoCandidatoForm = (): ICandidaditaForm => {
  return {
    nome: '',
    email: '',
    celular: '',
    resumo: '',
    setor_pretendido: '',
    cargo_pretendido: '',
    a24_ukey: null,
    curriculo: '',
    rh_vaga_ukey: null,
    cpf: '',
    pcd: 0,
    pcd_detalhes: [],
  }
}

export const Candidatura = () => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [mostraTelaSucesso, setMostraTelaSucesso] = useState(false);
  const [error, setError] = useState('');
  const [vaga, setVaga] = useState<Vaga | null>(null);
  const [setores, setSetores] = useState<SelectInput[]>([]);
  const [currentItem, setCurrentItem] = useState<null | ICandidaditaForm>(null);

  const pcd_opcoes = [
      "Física", "Mental", "Auditiva", "Visual", "Intelectual"
  ];

  const { rh_vaga_ukey } = useParams();


  useEffect(() => {
    const getData = async () => {
      let vagas: Vaga[] = [];

      try {
        const { data } = await apiCall({ url: `/portal-candidatura/vagas`, method: 'GET' });

        vagas = data;

        const { data: dataSetores } = await apiCall({ url: `/portal-candidatura/setores`, method: 'GET' });

        setSetores(dataSetores);
        setIsLoading(false);
      } catch (err: any) {
        setIsLoading(false);
      }

      const vaga = vagas.find((i) => i.ukey == rh_vaga_ukey);

      if (!vaga) {
        setCurrentItem(novoCandidatoForm())
        return;
      }

      setVaga(vaga);

      setCurrentItem({
        ...novoCandidatoForm(),
        rh_vaga_ukey: vaga.ukey,
        cargo_pretendido: vaga.nome,
        setor_pretendido: vaga.setor,
      })
    }

    getData();
  }, [rh_vaga_ukey]);

  if (mostraTelaSucesso) {
    return (
      <Layout>
        <Box mt={15} borderRadius={"15px"} bg={"green.100"} borderColor={"green.300"} textAlign={"center"} p={5} marginTop={"30vh"} borderWidth="1px" mx={4}>
          <b style={{ fontSize: '18px' }}>Candidatura enviada com sucesso!</b> <br /><br />

          Entraremos em contato por e-mail ou pelo celular caso seu perfil seja selecionado!
        </Box>
      </Layout>
    )
  }

  if (true) {
    return (
      <Layout>
        <Box mt={15} mx={"auto"} maxW={"700px"}>

          {!!error && <Box borderRadius={"5px"} bg={"red.100"} borderColor={"red.300"} borderWidth={"1px"} p={4} textAlign={"center"} mb={4}>
            {error}
          </Box>}

          {isLoading && <Box mb={4} width={"full"} textAlign={"center"}>
            <CircularProgress isIndeterminate color={colorsConfig.bgColor} />
          </Box>}

          {!!vaga && <Box width={"full"} px={8}>
            <Heading textAlign={"center"} color={colorsConfig.bgColor} size={"md"} width={"full"}>
              {vaga.nome}
            </Heading>

            {!!vaga.atividades_exercidas && <Box color={colorsConfig.bgColor} mt={6} borderColor={colorsConfig.bgColor} borderBottomWidth={"1px"} width={"full"} fontSize={"18px"}>
              Principais Atividades
            </Box>}

            <Box mt={2}>
              <div dangerouslySetInnerHTML={{ __html: vaga.atividades_exercidas }} />
            </Box>

            {!!vaga.requisitos && <Box color={colorsConfig.bgColor} mt={6} borderColor={colorsConfig.bgColor} borderBottomWidth={"1px"} width={"full"} fontSize={"18px"}>
              Requisitos
            </Box>}

            <Box mt={2}>
              <div dangerouslySetInnerHTML={{ __html: vaga.requisitos }} />
            </Box>

            {!!vaga.beneficios && <Box color={colorsConfig.bgColor} mt={6} borderColor={colorsConfig.bgColor} borderBottomWidth={"1px"} width={"full"} fontSize={"18px"}>
              Benefícios
            </Box>}

            <Box mt={2}>
              <div dangerouslySetInnerHTML={{ __html: vaga.beneficios }} />
            </Box>

            {!!vaga.regiao && <Box color={colorsConfig.bgColor} mt={6} borderColor={colorsConfig.bgColor} borderBottomWidth={"1px"} width={"full"} fontSize={"18px"}>
              Local de Trabalho
            </Box>}

            <Box mt={2}>
              {vaga.regiao}
            </Box>

          </Box>}
          {!vaga && !isLoading &&
            <Box width={"full"} px={8}>
              <Heading textAlign={"center"} color={colorsConfig.bgColor} size={"md"} width={"full"}>
                BANCO DE CURRÍCULOS
              </Heading>
            </Box>}

          {currentItem && <Formik
            enableReinitialize
            initialValues={currentItem}
            onSubmit={async (val, { setErrors }) => {

              const isOk = validateForm({
                nome: 'min|5',
                cpf: 'required',
                celular: 'celular',
                resumo: 'required',
                setor_pretendido: 'required',
                cargo_pretendido: 'required',
                a24_ukey: 'required',
                curriculo: 'required',
                pcd_detalhes: 'min|1',
              }, val)

              if (val.pcd == 0) {
                val.pcd_detalhes = [];
                delete (isOk as any).pcd_detalhes;
              }

              if (Object.keys(isOk).length > 0) {
                setErrors(isOk)
                mensagemErro("Corriga os erros e salve novamente.", 2000)
                return;
              }
                if (isSending) return;
                setIsSending(true);
                setTimeout(async () => {
                  try {
                    const { data } = await apiCall({ url: '/portal-candidatura/enviar', data: val, method: 'POST' })

                    if (data?.status == 'success') {
                      setIsSending(false);
                      setMostraTelaSucesso(true);
                      return;
                    }

                    mensagemErro('Ocorreu um erro! Não foi possível enviar a candidatura. Tente novamente mais tarde.')
                  } catch (err: any) {
                    mensagemErro(`Ocorreu um erro na candidatura: ${formatError(err)}`, 5000);
                    setIsSending(false);
                  }
                }, 100) // Timeout para problemas de duplo clique
            }}
          >
            {({ values }) => (
              <Form>
                <Flex width="full" wrap="wrap" px={8}>

                <InputField
                    label="CPF *"
                    name="cpf"
                    width={"full"}
                    placeholder="Seu CPF"
                    mask="999.999.999-99"
                  />
                  <InputField
                    label="Nome completo *"
                    name="nome"
                    width={"full"}
                    placeholder="Seu nome completo"
                  />
                  <InputSelect
                    label="É portador de necessidades especiais? *"
                    width={"full"}
                    name="pcd"
                  >
                    <option key={0} value={0}>Não</option>
                    <option key={1} value={1}>Sim</option>
                  </InputSelect>
                  {values.pcd == 1 ? <InputCheckboxGroup label="Selecione" name="pcd_detalhes" options={pcd_opcoes} /> : null}
                  <BobjetoPadrao
                    label="Cidade *"
                    placeholder="Cidade onde mora"
                    name="a24_ukey"
                    width="100%"
                    endpoint="/portal-candidatura/cidade-bobjeto"
                    visualizacao={(item) => <>{item.cidade} - {item.uf}</>}
                  />
                  <InputSelect
                    label="Área *"
                    width={"full"}
                    name="setor_pretendido"
                    disabled={!!values.rh_vaga_ukey}
                  >
                    <option value="">Selecione...</option>
                    {setores.map((setor) => (
                      <option key={setor.value} value={setor.value}>{setor.name}</option>
                    ))}
                  </InputSelect>
                  <InputField
                    label="Cargo pretendido *"
                    name="cargo_pretendido"
                    width={"full"}
                    placeholder="Cargo em que pretende atuar"
                  />
                  <InputField
                    label="Escreva um breve resumo de sua experiência *"
                    name="resumo"
                    width={"full"}
                    placeholder="Escreva um breve resumo de sua experiência"
                    textarea={true}
                    rows={5}
                    labelFontSize="15px"
                  />
                  <InputField
                    label="Seu melhor email"
                    name="email"
                    width={"full"}
                    placeholder="Seu melhor email"
                    type="email"
                  />
                  <InputField
                    label="Celular com DDD *"
                    name="celular"
                    width={"full"}
                    type="tel"
                    mask="(99)99999-9999"
                  />
                  <InputFile
                    label="Currículo"
                    placeholder="+Anexar currículo em PDF"
                    name="curriculo"
                    width={"full"}
                  />
                  <Button disabled={isSending} size="sm" type="submit" mt={4} mx={1} borderRadius={"15px"} bg="#6d6d6d" color="#232323" height={"2.5rem"} width={"full"} colorScheme="blackAlpha">
                    {isSending ? <><CircularProgress color={colorsConfig.bgColor} isIndeterminate size={"20px"} />&nbsp;Enviando candidatura...</> : 'Candidatar para a vaga'}
                  </Button>

                  <Box fontSize={"13px"} px={2} mt={4}>
                    Ao continuar, você concorda com nossa política de Cookies e termos de tratamento de dados de acordo com a nossa política de privacidade: <br />
                    <Link color={'#218bff'} href="https://moinhoglobo.com.br/politica-de-privacidade">
                      https://moinhoglobo.com.br/politica-de-privacidade
                    </Link>
                  </Box>
                </Flex>
              </Form>)}
          </Formik>}

        </Box>
      </Layout>
    )
  }

  return (
    <Layout><></></Layout>
  )
}

import { Container, Flex } from "@chakra-ui/react";
import { ReactNode } from "react"

type IProps = {
  children: ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
}

export const Layout = ({ children, footer, header }: IProps) => {
  return (
    <Flex bgColor="white" direction="column" w="full" minH="80vh" position="relative">
      {header && <> {header}</>}
      <Container as="main" p={0} mb={16} maxW={'100%'}>
        {children}
      </Container>
      {footer && <> {footer}</>}
    </Flex >
  )
}
import { Flex, FormControl, FormErrorMessage, FormLabel, Input, List, ListItem, Spinner } from "@chakra-ui/react"
import { useField } from "formik";
import { useState, useEffect, ReactNode } from 'react'
import { FiX } from "react-icons/fi";
import { apiCall } from "../../api/apiNoAuthCall";

interface IProps {
  label: string;
  name: string;
  visualizacao: (item: any) => string | ReactNode;
  endpoint: string;
  width?: string;
  placeholder?: string;
  onSelectItem?: (item: any) => void;
}

export const BobjetoPadrao = ({ label, name, endpoint, width = `100%`, placeholder, visualizacao, onSelectItem }: IProps) => {
  const [, { error, touched, value }, { setValue }] = useField({ name });

  const [searchResults, setSearchResults] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const { data } = await apiCall({ url: `${endpoint}?termo=${searchTerm}`, method: 'GET' });
        setIsLoading(false)
        setSearchResults(data);
      } catch (error) {
        console.error('Erro ao buscar dados da API', error);
        setIsLoading(false)
      }
    };

    const debounceTimer = setTimeout(() => {
      if (searchTerm.trim() !== '') {
        fetchData();
      } else {
        setSearchResults([]);
      }
    }, 300);

    return () => clearTimeout(debounceTimer);

  }, [searchTerm, endpoint]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const { data } = await apiCall({ url: `${endpoint}/${value}`, method: 'GET' });
        setIsLoading(false)
        setSelectedItem(data);
        typeof onSelectItem === 'function' && onSelectItem(data);
      } catch (error) {
        console.error('Erro ao buscar dados da API', error);
        setIsLoading(false)
      }
    };

    const debounceTimer = setTimeout(() => {
      if (!value) {
        setSelectedItem(null);
        typeof onSelectItem === 'function' && onSelectItem(null);
      } else {
        fetchData();
      }
    }, 100)

    return () => clearTimeout(debounceTimer);
  }, [value]);


  const handleSelectItem = (item: any) => {
    console.log(item);
    setValue(item.ukey as any)
    setSelectedItem(item)
    typeof onSelectItem === 'function' && onSelectItem(item);
  }

  const removeItem = () => {
    setValue('')
    setSelectedItem(null)
    typeof onSelectItem === 'function' && onSelectItem(null);
  }

  return (
    <Flex width={width} position={'relative'}>
      {!value && !selectedItem ? <FormControl width={'100%'} isInvalid={touched && error ? true : false} p={1}>
        {label && <FormLabel pl={2} mb={0} mt={2}><em>{label}</em></FormLabel>}
        <Input
          position={'relative'}
          bg={'white'}
          color={'black'}
          placeholder={placeholder ? placeholder : label}
          isInvalid={touched && error ? true : false}
          borderRadius={'15px'}
          borderColor={'#6d6d6d'}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {isLoading && <Flex zIndex={99} position={'absolute'} right={'10px'} top={'43px'}><Spinner /></Flex>}
        {error && <FormErrorMessage ml={2} mt={1}>{error}</FormErrorMessage>}
      </FormControl> :
        <Flex direction={"column"} width={"full"} p={1}>
          {label && <FormLabel pl={2} mb={0} mt={2}><em>{label}</em></FormLabel>}
          <Flex borderColor={'#6d6d6d'} position={'relative'} alignItems={'center'} p={2} width={"full"} borderRadius={'15px'} bg={'gray.300'} color={'black'} height={'40px'}>
            {visualizacao(selectedItem)}

            <Flex onClick={removeItem} _hover={{ backgroundColor: '#d3d3d3' }} cursor={'pointer'} p={1} borderRadius={'5px'} position={'absolute'} top={isLoading ? '5px' : '7px'} right={'5px'}>
              {isLoading ? <Spinner /> : <FiX />}
            </Flex>
          </Flex>
        </Flex>
      }
      {!isLoading && !value && (searchResults?.data ?? []).length > 0 && <List maxH={"50vh"} overflowY={"auto"} mt={2} position={"absolute"} p={2} bg={'white'} borderRadius={'5px'} width={'100%'} top={'70px'} zIndex={99}>
        {(searchResults?.data ?? []).map((result: any) => (
          <ListItem
            key={result.ukey}
            onClick={() => handleSelectItem(result)}
            cursor="pointer"
            bg={"gray.100"}
            p={2}
            _hover={{ bg: 'gray.200' }}
            borderRadius={'5px'}
            mb={1}
          >
            {visualizacao(result)}
          </ListItem>
        ))}
      </List>}
    </Flex>
  )
}